module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50,"duration":400},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"diverse","short_name":"diverse","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"static/favicon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5ebecca29d06a107a03daa758cfaa749"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/freeProposal/","/coomingSoon/","/404/"],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"zIndex":1001,"backdropFilter":"blur(8px)","WebkitBackdropFilter":"blur(8px)"},"content":{"margin":0,"padding":0,"left":"50%","top":"50%","bottom":"auto","right":"auto","transform":"translateX(-50%) translateY(-52%)","borderRadius":"25px"}},"shouldCloseOnOverlayClick":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5QQH65M","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
